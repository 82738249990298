export default {
  common: {
    colon: ': ',
    submit: 'Submit',
    resend: 'Resend',
    cancel: 'Cancel',
    confirm: 'Confirm',
    backToTickets: 'Back to all tickets',
    backToTicketDetails: 'Back to ticket details',
    tickets: 'All tickets',
    day: 'D',
    hour: 'H',
    min: 'MIN',
    second: 'SEC',
    useChrome: 'Please use latest Chrome browser.',
    recommendBrowserTips:
      'Your browser is currently not supported, please use a recommended browser.',
    copyBtn: 'Copy the URL',
    specialWebviewTip1:
      'This version might not be available with the version of your iOS device.',
    specialWebviewTip2:
      'Please update your iOS device to the latest version or use a different device.'
  },
  advanced: {
    title: 'Staff features',
    success: 'Staff processing is complete.'
  },
  booking: {
    details: 'Reservation details',
    history: 'Usage history',
    bookedDate: 'Reservation date',
    participationLocations: 'Participation location',
    participationLocationKind: {
      MAP_OF_MEETING_POINT: 'Meeting point',
      NOTE_OF_LOCATION_AND_DIRECTION: 'Note of location and direction',
      LANDMARKS_TO_MEETING_POINT: 'Nearby landmarks',
      PICK_UP_CAR: 'Pick-up vehicles',
      PICK_UP_GUIDE: 'Transportation personnel',
      ITINERARY: 'Itinerary',
      PARKING: 'Parking information'
    },
    inclusion: 'Inclusion',
    restrictions: 'Restrictions',
    requirements: 'Requirements',
    attireItems: 'Required items to bring & attire',
    otherInfo: 'Remarks / Others',
    cancellationPolicy: 'Cancellation policy'
  },
  history: {
    title: 'Usage history'
  },
  otp: {
    title: 'One-time password',
    tips1: 'Your access environment has changed.',
    tips2: 'For security protection, a one-time password will be sent.',
    tips3:
      'Please register your email address to receive the one-time password.',
    inputOTPCode: 'Please enter the one-time password provided in the email.',
    sendCode:
      'A one-time password has been sent to {{email}}. Please check your email.',
    invalidEmail: 'Invalid Email address.',
    inputEmail: 'Please enter your Email address.',
    inputPassword: 'Please enter the one-time password.',
    sendOTP: 'Send one-time password.'
  },
  ticket: {
    status: {
      INVALID: 'Invalid',
      AWAITING: 'Before the period',
      UNUSED: 'Not yet used',
      USABLE: 'Available for use',
      IN_USE: 'In use',
      USED: 'Used',
      EXPIRED: 'Expired',
      DISTRIBUTED: 'Distributed',
      RETURNED: 'Returned'
    },
    subStatus: {
      ENTERED: 'Entered the station',
      EXITED: 'Departed from the station',
      INITIAL_STATION_CANCELLED: 'Departure station cancellation'
    },
    nowTime: 'Current time',
    startTime: 'Start time',
    passed: 'has passed',
    QRCodeExpirationRemainingTime: 'QR code validity period remaining',
    screenShotTips:
      'Please note that you cannot gain entry using a screenshot of the QR code.',
    showScreenToStaff: 'Please present this screen to the staff.',
    instruction: 'Usage instructions',
    usedAt: 'Date of use',
    availablePeriod: 'Available Period',
    bookingId: 'Booking ID',
    startUsing: 'Start using',
    startUsingTips: 'Would you like to use your ticket now?',
    distributeTicket: 'Distribute tickets',
    distributeTicketTips:
      'If you cannot copy the link from the share button, please copy the above link to share the tickets with your companions.',
    reDistributeTicket: 'Redistribute tickets',
    reDistributeTips:
      '*Ticket redistribution is only permitted if a person has an unused ticket.',
    reDistributeTips2:
      '*The distributed link will become invalid after redistribution.',
    copyLinkSuccess: 'The link has already been copied.',
    use: 'Use',
    confirmUse: 'Confirm usage details',
    useTicket: 'Use <span>{{count}}</span> tickets',
    issueTicketTips: 'Please use it in front of the staff',
    issueTicketNotes: '*Cancellation is not possible after use',
    undo: 'Undo',
    redeem: 'Redeem ticket',
    thanks: 'Thanks',
    second: '{{count}} SEC',
    expirationDate: 'Expiration date',
    supplier: 'Supplier',
    participatorName: 'Participant name',
    validPeriod: 'Valid period after used',
    fixedTime: '{{day}} days (until {{time}} on the last day, local time)',
    enableCamera: 'Please enable the camera'
  },
  apiErrors: {
    baseError: 'There is an error occurred.',
    clientError: 'A error occurred while sending API request.',
    serverError: 'API server failed to fulfil the request.',
    connectionError:
      'An error occurred while connecting to API server. Please check your network and try again later.',
    requestError: 'An error occurred while setting up an API request.'
  }
};
